.contact-data {
  padding-top: 4vw;
  padding-bottom: 2vw;
  display: flex;

  @include mq($until: xs) {
    flex-direction: column;
    justify-content: center;
    padding-top: 55px;
  }
  &__content p {
    color: #fff;
  }
  .logos {
    display: flex;
    flex-direction: column;
    padding-right: 5vw;
    align-items: center;
    @include mq($until: sm) {
      padding-right: 8vw;
    }
    @include mq($until: xs) {
      padding-bottom: 34px;
      padding-right: unset;
    }
  }

  .footer {
    a {
      color: #fff;
    }

    display: flex;
    flex-direction: column;
    align-items: center;
    &__addresss {
      width: 40vw;
    }
    &__name {
      padding-top: 28px;
      padding-bottom: 13px;
      color: #fff;
    }

    &__icon {
      padding-right: 8px;
      svg {
        fill: #fff;
        width: 21px;
      }
    }
    &__item {
      margin-bottom: 13px;
      a {
        color: #fff;
      }
      transition: all 0.3s ease-in-out;
      &:hover {
        svg {
          fill: #4f637c;
        }
        a {
          color: #4f637c;
        }
      }
    }
    &__slogan {
      margin-top: 8px;
      font-style: italic;
      color: #fff;
    }
  }
  &__fb {
    fill: #fff;
    width: 21px;
    transition: all 0.4s ease-in-out;
    margin: 5px;
    &:hover {
      fill: #4f637c;
    }
  }
  &__insta {
    fill: #fff;
    width: 21px;
    transition: all 0.4s ease-in-out;
    margin: 5px;
    padding-right: 6px;

    &:hover {
      fill: #4f637c;
    }
  }
}
