.button {
  color: #4f637c;
  padding: 15px 25px;
  transition-property: color, border, background-color;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  background-color: #fff;
  display: inline-block;
  line-height: 1;
  text-align: center;
  border: 1px solid #4f637c;
  align-self: flex-start;
  cursor: pointer;
  width: 100%;
  font-size: rem(17px);

  &:hover {
    background-color: transparent;
    color: #8fa576;
    border-color: #8fa576;
  }

  &--secondary {
    background-color: transparent;
    color: #4f637c;
    border-color: #4f637c;

    &:hover {
      color: #8fa576;
      background-color: #8fa576;
      border-color: #8fa576;
    }
  }
}
