.flat-info {
  &__header {
    padding: 40px 70% 20px 0;
    background-color: $color-main;
    position: relative;
    @include mq($until: md) {
      padding-right: 0;
    }
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      height: 100%;
      width: 100vw;
      background-color: $color-main;
      z-index: 0;
    }
  }
  &__heading {
    color: $color-font-light;
    font-size: rem(28px);
    text-transform: uppercase;
    position: relative;
    margin-bottom: 0;
    padding: 0;
    @include mq($until: xxl) {
      font-size: rem(22px);
    }
  }
  &__content-wrapper {
    display: grid;
    grid-template-columns: 30% 40% 30%;
    justify-content: stretch;
    align-items: stretch;
    @media (max-width: 1366px) {
      grid-template-columns: 40% 60%;
      grid-template-areas: "details location"
                            "image image";
    }
    // @include mq($until: lg) {

    // }
    // @include mq($until: lg) {
    //   grid-template-columns: 50% 50%;
    // }
    @include mq($until: md) {
      grid-template-columns: 1fr;
      grid-template-areas: "details"
                           "location"
                           "image";
    }
  }
  &__details {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-right: 60px;
    @media (max-width: 1366px) {
      grid-area: details;
    }
    // @include mq($until: lg) {
      
    // }
    @include mq($until: md) {
      padding-right: 0;
    }
  }
  &__details-header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 20px 0 20px 20px;
    margin-bottom: 20px;
    border-bottom: 2px solid $color-extra;
    width: 100%;
  }
  &__back {
    background-color: #fff;
    padding: 0 15px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: rem(16px);
    margin-right: auto;
    border: none;
    position: relative;
    transition: 0.3s;
    &:hover {
      background-color: $color-extra;
      &:before {
        border-right-color: $color-extra;
      }
    }
    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 100%;
      transition: 0.3s;
      width: 0;
      height: 0;
      border-top: 15px solid transparent;
      border-bottom: 15.5px solid transparent;
      border-right: 16px solid #fff;
    }
  }
  &__building {
    font-size: rem(18px);
  }
  &__floor {
    font-size: rem(18px);
    margin-left: 20%;
  }
  &__rooms-wrapper {
    width: 100%;
    margin: 30px 0;
  }
  &__area {
    color: $color-font-dark;
    font-size: rem(24px);
    font-weight: $fw-bold;
    margin-bottom: 30px;
    display: block;
  }
  &__rooms {
    padding: 0;
    margin: 0;
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 30px;
    }
  }
  &__room {
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
    font-size: rem(18px);
    &:last-child {
      margin-bottom: 0;
    }
    &:before {
      display: none;
    }
  }
  &__room-number {
    height: 25px;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid $color-extra;
    font-weight: $fw-light;
    margin-right: 10px;
    padding: 2px 3px 0 0;
    font-size: rem(18px);
    font-style: italic;
  }
  &__room-name {
    font-style: italic;
  }
  &__room-area {
    margin-left: auto;
    font-weight: $fw-bold;
    font-size: rem(18px);
    white-space: nowrap;
    // @include mq($until: md) {

    // }
  }
  &__buttons {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 48px 48px;
    grid-template-areas:
      "contact download"
      "compare compare";
    grid-gap: 20px;
    justify-content: center;
    align-items: center;
    @media (max-width: 1660px) {
      grid-template-columns: 1fr;
      grid-template-areas:
      "contact"
      "download"
      "compare";
    }
    // @include mq($until: xl) {
    //   grid-template-columns: 1fr;
    //   grid-template-areas:
    //   "contact"
    //   "download"
    //   "compare";
    // }
    @include mq($until: md) {
      grid-template-columns: repeat(2, 1fr);
      grid-template-areas:
      "contact download"
      "compare compare";
    }
    @include mq($until: xs) {
      grid-template-columns: 1fr;
      grid-template-areas:
      "contact"
      "download"
      "compare";
    }
    .button {
      width: 100%;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 48px;
      &:hover {
        background-color: $color-extra;
        border-color: $color-extra;
        color: $color-font-dark;
      }
      svg {
        width: 25px;
        max-height: 29px;
        margin-right: 10px;
        @include mq($until: xxl) {
          width: 20px;
          max-height: 24px;
        }
      }
    }
  }
  &__contact {
    grid-area: contact;
  }
  &__get-card {
    grid-area: download;
  }
  &__compare {
    grid-area: compare;
    overflow: hidden;
    position: relative;
    div{
      display: flex;
      color: $color-font-dark;
      // font-size: inherit;
      &.add{
        position: relative;
        transition: transform .3s;
        color: inherit;
      }
      &.remove{
        position: absolute;
        top: 100%;
        transition: transform .3s;
        color: inherit;
      }
    }
    &.compare-remove{
      background-color: #fff;
      border-color: #ff6c7a;
      transition: .3s;
      .add{
        transform: translateY(-150%);
      }
      .remove{
        color: #ff6c7a;
        svg{
          fill: #ff6c7a;
        }
        transform: translateY(-130%);
        @include mq($until: xl) {
          transform: translateY(-140%);
        }
      }
      &:hover{
        background-color: #ff6c7a;
        border-color: #ff6c7a;
        .remove{
          color: #fff;
          svg{
            fill: #fff;
          }
        }
      }
    }
  }
  &__selected-building {
    margin-top: 100px;
    margin-bottom: 50px;
    width: 100%;
    @include mq($until: md) {
      margin-top: 70px;
      margin-bottom: 100px;
    }
  }
  &__floor-image{
    display: grid;
    grid-template-columns: 1fr minmax(50px, 111px);
    justify-content: center;
    align-items: center;
    .step2__floor{
      min-width: 250px;
      width: 100%;
    }
    @include mq($until: xs) {
      position: relative;
      justify-items: center;
      margin-bottom: 40px;
      grid-template-columns: 1fr;
      .step2__floor{
        order: 2;
        z-index: 0;
      }
    }
  }
  &__direction-image{
    width: 100%;
    @include mq($until: xs) {
      position: absolute;
      top: 0px;
      right: 0px;
      width: 15vw;
      z-index: 1;
      order: 1;
    }
  }
  &__images {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
    top: -50px;
    background-color: #fff;
    padding: 40px;
    height: calc(100% + 50px);
    @media (max-width: 1366px) {
      grid-area: location;
    }
    @include mq($until: xs) {
      padding: 20px;
    }
  }
  &__slider{
    @include mq($until: xs) {
      margin-bottom: 50px;
    }
  }
  &__flat-image {
    max-width: 100%;
    max-height: 500px;
    margin: 0 auto;
    display: block;

  }
  &__flat-direction {
    width: 100%;
    text-align: center;
    font-size: rem(20px);
    font-weight: $fw-semi-bold;
    margin: 60px 0;
    span {
      font-size: inherit;
      color: $color-extra;
      font-weight: $fw-regular;
    }
  }
  &__view {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    &:hover {
      background-color: $color-extra;
      border-color: $color-extra;
      color: $color-font-dark;
    }
    svg {
      width: 25px;
      margin-right: 10px;
    }
  }
  &__building-front {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    top: -50px;
    height: calc(100% + 50px);
    @media (max-width: 1366px) {
      grid-area: image;
      height: 40vw;
      top: auto;      
    }

  }
}

.flat-location {
  &__building-path {
    fill: transparent;
    &.active {
      fill: $color-extra;
      stroke: black;
      stroke-width: 0.3px;
      stroke-linecap: butt;
      stroke-miterlimit: 2;
      opacity: 0.6;
    }
  }
}

.step1 {
  &__building {
    width: 100%;
    max-height: calc(100vh - 200px);
    display: none;
    & > svg {
      position: absolute;
      max-height: calc(100vh - 200px);
      width: auto;
      left: 50%;
      transform: translateX(-50%);
      max-width: calc(100% - 100px);
    }
    @include mq($until: xxl) {
      // max-height: 900px;
    }
    &.active {
      display: block;
    }
  }
  &__floor {
    opacity: 0;
    transition: opacity 0.3s;
    cursor: pointer;
    &:hover {
      opacity: 0.5;
    }
  }
}

.step2 {
  &__floor {
    max-width: 2000px;
    z-index: 2;
    display: none;
    transition: opacity 0.3s;
    opacity: 0;
    overflow: hidden;
    position: relative;

    &.active {
      display: block;
      overflow: visible;
      opacity: 1;
    }

    &--static {
      display: block;
      opacity: 1;
      path,
      polygon,
      rect {
        fill: transparent;
        &:hover {
          fill: transparent;
        }
      }
    }
  }
}
